/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React, { useMemo } from "react"
import { useStaticQuery, graphql } from "gatsby"
import { Helmet } from "react-helmet"

function Seo({ pageContext }) {
  // title={pageData.seo_title}
  // image={pageData.seo_image.url}
  // description={pageData.seo_description}
  // langs={pageData.pageOnOtherLangs}
  // esNoIndexable={pageData.seo_no_indexable}

  const {
    seo_description,
    seo_title,
    title: pageTitle,
    seo_image,
    pageOnOtherLangs,
    seo_no_indexable,
    lang,
  } = pageContext
  // const { trackingData } = langSettings
  // const { googleTagManager, googleAnalytics } = trackingData
  const title = seo_title || pageTitle || "OB Cocinas"
  const description = seo_description
  const image = seo_image.url
  const esNoIndexable = seo_no_indexable

  const data = useStaticQuery(graphql`
    query seoData {
      site {
        siteMetadata {
          domain
          author
          googleTagManager
        }
      }
    }
  `)
  const domain = data.site.siteMetadata.domain
  const author = data.site.siteMetadata.author
  const googleTagManager = data.site.siteMetadata.googleTagManager

  const pageOnOtherLangsCategorized = useMemo(
    () => ({
      current: pageOnOtherLangs.find(otherPage => otherPage.current),
      otherLangs: pageOnOtherLangs.filter(otherPage => !otherPage.current),
    }),
    [pageOnOtherLangs]
  )
  const currentUrl = `${domain}${pageOnOtherLangsCategorized.current.ruta}`

  const meta = [
    {
      name: `description`,
      content: description,
    },
    {
      property: `og:title`,
      content: title,
    },
    {
      property: `og:description`,
      content: description,
    },
    {
      property: `og:type`,
      content: `website`,
    },
    {
      property: `og:url`,
      content: currentUrl,
    },
    {
      property: `og:image`,
      content: image,
    },
    {
      name: `twitter:card`,
      content: `summary`,
    },
    {
      name: `twitter:creator`,
      content: author,
    },
    {
      name: `twitter:title`,
      content: title,
    },
    {
      name: `twitter:description`,
      content: description,
    },
    {
      name: `twitter:site`,
      content: currentUrl,
    },
    {
      name: `author`,
      content: author,
    },
    {
      name: `twitter:image`,
      content: image,
    },
    {
      name: `robots`,
      content: esNoIndexable ? `noindex, nofollow` : `index, follow`,
    },
  ]

  const schemaOrg = {
    "@context": "http://schema.org",
    "@type": "Organization",
    name: "OB Cocinas",
    alternateName: "Fabricantes de Cocinas - OB Cocinas",
    url: domain,
    logo: {
      "@type": "ImageObject",
      url: `${domain}/icons/icon-512x512.png`,
    },
    sameAs: [
      "https://www.facebook.com/OBCocinas",
      "https://www.linkedin.com/company/ob-cocinas",
      "https://www.instagram.com/obcocinas/",
    ],
  }

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      meta={meta}
    >
      {googleTagManager && (
        <script>
          {`
          (function (w, d, s, l, i) {
            w[l] = w[l] || []; w[l].push({
              'gtm.start':
                new Date().getTime(), event: 'gtm.js'
            }); var f = d.getElementsByTagName(s)[0],
              j = d.createElement(s), dl = l != 'dataLayer' ? '&l=' + l : ''; j.async = true; j.src =
                'https://www.googletagmanager.com/gtm.js?id=' + i + dl; f.parentNode.insertBefore(j, f);
          })(window, document, 'script', 'dataLayer', '${googleTagManager}');
        `}
        </script>
      )}
      {pageOnOtherLangs && (
        <link
          key={pageOnOtherLangsCategorized.current.lang}
          rel="canonical"
          href={currentUrl}
        />
      )}
      {pageOnOtherLangs &&
        pageOnOtherLangsCategorized.otherLangs.map(otherPage => {
          return (
            <link
              key={otherPage.lang}
              rel="alternate"
              href={otherPage.ruta}
              hrefLang={otherPage.lang}
            />
          )
        })}
      <script type="application/ld+json">
        {JSON.stringify(schemaOrg, null, "\t")}
      </script>
    </Helmet>
  )
}

export default Seo
