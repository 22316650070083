// Componentse temporales para reemplazar a gatsbyPluginTransitionLink hasta que se encuentre una alternativa.
import React from 'react';

export const TransitionState = ({ children }) => {
  // Simulamos un estado de transición "entered"
  const transitionState = {
    transitionStatus: "entered",
    exit: {},
    entry: {},
  };

  // Llamamos a la función children con el estado de transición simulado
  return children(transitionState);
};
