import React, { useContext } from "react"
import isClient from "./isClient";
import htmr from 'htmr'
import { GeneralContext } from "../contexts/generalContext"
import { getGAEvent } from './gTags'
import CookieBot from "../components/mini/CookieBot";

export default function htmlFilters( htmlString ) {
    let transform = {}
    if(isClient){
        //Gtag Event To Phone Links
        transform.a = (props) => {
                if(props.href.substring(0,4)==='tel:'){
                    return <a href={props.href} {...getGAEvent('enlace_movil', null)}>{props.children}</a>;
                }

              return <a href={props.href}>{props.children}</a>;
            }
        //cookies configurator
        transform.strong = (props) => {
          if(props.children && props.children[0]==="[configurador_de_cookies]"){
            return <CookiesConfigButton {...props} />;
          }
          return <strong>{props.children}</strong>
        }

        // CookieBot Compliance
        transform.p = (props) => {
          if(props['className'] && props['className'] === "cookie-bot-compliance" && props.children[0].startsWith('CookieBotCompliance:')){
            const domainGroupId = props.children[0].split(':')[1]
            return <CookieBot domainGroupId={domainGroupId}/>;
          }
          return <p {...props}>{props.children}</p>
        }
        

    }
    const result = htmr(htmlString, { transform })
    return result;
}


const CookiesConfigButton = (props) => {
  const generalContext = useContext(GeneralContext)
  const { t,  setForceShowCookies } = generalContext

  return(
    <a
      {...props}
      style={{
        cursor: 'pointer',
      }}
      onClick={() => setForceShowCookies(true)} >{t.cookies.config}</a>
  )
}