import React, { useEffect, useRef, useContext } from 'react'
import { css } from 'twin.macro'
import { GeneralContext } from "../../contexts/generalContext"

const CookieBot = ({ domainGroupId = '' }) => {
  const cookiesContainerRef = useRef(null)
  const generalContext = useContext(GeneralContext)
  const { lang } = generalContext

  useEffect(() => {
    const script = document.createElement('script')
    script.id = 'CookieDeclaration'
    script.src = `https://consent.cookiebot.com/${domainGroupId}/cd.js`
    script.setAttribute('data-culture', lang)
    script.type = 'text/javascript'
    script.async = true
    if (cookiesContainerRef.current) {
      cookiesContainerRef.current.innerHTML = ''
      cookiesContainerRef.current.appendChild(script)
    }
    return () => {
      if (cookiesContainerRef.current) {
        cookiesContainerRef.current.removeChild(script)
      }
    }
  }, [])

  return <div
    id='cookie-declaration-container'
    css={cookiesContainerCSS}
    ref={cookiesContainerRef}
  ></div>
}

export default CookieBot
const cookiesContainerCSS = css`
  & > div:not(:first-child) {
   display: none;
  }
`