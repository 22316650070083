import * as React from "react"
// import PropTypes from "prop-types"
import tw from "twin.macro"
import { getGAEvent } from '../../helpers/gTags'

import Catalogo from "../vector/catalogo"

const DescargaPdf = ({
  color,
  bgColor,
  enlace,
  texto,
  showInMobile,
  isCentered,
  layout = "vertical", //horizontal | vertical
    dataType,
    dataName,
}) => {
  const [hover, setHover] = React.useState(false)
  //  dataType y dataName se usan para rellenar los gTags
  return (
    <a
      css={[
        tw`hidden md:inline-block text-right py-5`,
        showInMobile && tw`inline-block`,
        isCentered && tw`text-center`,
        layout === "horizontal" && tw`md:flex md:flex-row md:justify-end md:items-end md:space-x-4 md:py-0`,
      ]}
      href={enlace}
      onMouseEnter={() => {
        setHover(true)
      }}
      onMouseLeave={() => {
        setHover(false)
      }}
      target="_blank"
      rel="noreferrer"

      {...getGAEvent('descarga_pdf', {dataName: dataName, dataType: dataType, })}
    >
      <Catalogo color={color} bgColor={bgColor} hover={hover} />
      <div
        css={[tw`pt-1 text-obsmall font-bold  uppercase`,
          layout === "horizontal" && tw`md:pb-2`,]}
        style={{
          color: color,
        }}
      >
        {texto}
      </div>
    </a>
  )
}

export default DescargaPdf
