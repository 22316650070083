import React, { useState } from "react"
import axios from "axios"

const CONTACT_FORM_URL = "https://api.obcocinas.es/es/form_api/"

export const contactFormSend = async (
  values,
  setSubmitting,
  formularioEnviado,
  formularioNombre,
  lang
) => {
  
  const params = new URLSearchParams()
  params.append("tipo", formularioNombre)
  params.append("email", values.email)
  params.append("razon", values.razon)
  params.append("nombre", values.nombre)
  params.append("consulta", values.message)
  params.append("pais", values.pais)
  params.append("cp", values.cp)
  params.append("movil", values.movil)
  params.append("sitio_origen", lang)
  await axios
    .post(`${CONTACT_FORM_URL}`, params, {
      headers: {
        // 'X-Requested-With': 'XMLHttpRequest',
        "Content-type": "application/x-www-form-urlencoded",
      },
    })
    .then(res => {
      //onsole.log("Formulario enviado")
      //onsole.log(res);
      setSubmitting(false)
      formularioEnviado(false)
    })
    .catch(error => {
      //lert("Error al enviar el formulario...")
      //onsole.log(error)
      setSubmitting(false)
      formularioEnviado(true)
    })
}
