
import * as React from "react"
import { useSpring, animated as a } from '@react-spring/web'
import tw from 'twin.macro';

const Catalogo = ({ color, bgColor,  hover }) => {
  const {trans} = useSpring({
    trans: hover ? 0.7 : 1,
    from: {
      trans: 0
    }
  })
  var emptyArray = [];
  var length = 5; // user defined length

  for(var i = 0; i < length; i++) {
    emptyArray.push(0);
  }
  return (
    <a.div
      css={tw`inline-block`}
    >
      <svg width="48" height="38" viewBox="0 0 48 38" fill="none" xmlns="http://www.w3.org/2000/svg">
        {emptyArray.map((object, index) => {
          return(
            <a.path key={index} d="M0.5 0.5L31.5 0.5L31.5 21.5L0.5 21.5L0.5 0.5Z" transform={trans.to( v => (`translate(${v*4*index}, ${v*4*index})`))} opacity={trans.to( v => ((index + 0.7) / 5 ))} fill={bgColor} stroke={color} strokeMiterlimit="10"/>
          )
        })}
      </svg>
    </a.div>
  )
}



export default Catalogo
